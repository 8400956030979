import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import { withPrefix } from 'gatsby'

export const IndexPageTemplate = ({ urls, items }) => {
  let rastgeleArtvizit = Math.floor(Math.random() * urls.length)
  rastgeleArtvizit = urls[rastgeleArtvizit]

  if (typeof window !== 'undefined') {
    window.location = withPrefix(rastgeleArtvizit)
  }

  return <div></div>
}

function redirectToLanguageSpecificPage() {
  const language = navigator.language || navigator.userLanguage;
  const langCode = language.substring(0, 2).toLowerCase();
  let url;

  console.log('langCode: ', langCode, 'language: ', language)

  switch (langCode) {
    case 'tr':
      url = '';
      break;
    default:
      url = 'en';
  }

  window.location.href = url;
}

IndexPageTemplate.propTypes = {
  pageContext: PropTypes.object,
}

const IndexPage = ({ pageContext }) => {
  const { urls, items } = pageContext
  const injectGA = () => {
    if (typeof window == 'undefined') {
      return
    }
    // redirectToLanguageSpecificPage();
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())

    gtag('config', 'G-577MST7J0C')
  }
  return (
    <Layout>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-577MST7J0C"
        />
        <script data-ad-client="ca-pub-9360151190449854" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        {/* <script type='text/javascript' dangerouslySetInnerHTML={{ __html: gaScript }} /> */}
        <script>{injectGA()}</script>
      </Helmet>
      <LanguageRedirector/>
      <IndexPageTemplate urls={urls} items={items} />
    </Layout>
  )
}


const LanguageRedirector = () => {
  useEffect(() => {
    redirectToLanguageSpecificPage();
  }, []);

  function redirectToLanguageSpecificPage() {
    const language = navigator.language || navigator.userLanguage;
    const langCode = language.substring(0, 2).toLowerCase();
    let url;

    // console.log('langCode: ', langCode, 'language: ', language)

    if(langCode !== 'tr' && !window.location.pathname.startsWith('/en')) {
      url = '/en';
      window.location.href = url;
    }
  }

  return null;
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    pageContext: PropTypes.object,
  }),
}

export default IndexPage
